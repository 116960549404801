import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import _data from './utils/_data'
import { Actions, Callbacks } from './app-actions'
import './project'
import {
  deleteAction,
  errorHandler,
  getAction,
  handleResponse,
  patchAction,
  postAction,
  updateAction,
} from './utils/saga-helpers'
import { AnyAction } from 'redux'
import { RequestTypes, RequestUrl } from './state-type'
import { mockUser } from './mocks'
import { useRouter } from 'next/router'

type IAction = Callbacks &
  AnyAction & {
    data?: any
  }

const fulfillWithTimeLimit = async (
  timeLimit: number,
  task: Promise<any>,
  failureValue: string,
) => {
  let timeout
  const timeoutPromise = new Promise((resolve, reject) => {
    timeout = setTimeout(() => {
      reject(new Error(failureValue))
    }, timeLimit)
  })
  const response = await Promise.race([task, timeoutPromise])
  if (timeout) {
    //the code works without this but let's be safe and clean up the timeout
    clearTimeout(timeout)
  }
  return response
}

// Called when the application starts up, if using SSR this is done in the server
export function* startup(action: IAction) {
  try {
    const { ...rest } = action.data || {}
    const token = action.data?.token

    if (token) {
      _data.setToken(token)
      // set the user
      yield onToken(action, {})
    }

    const isOnline = typeof navigator === 'undefined' ? true : navigator.onLine
    const data = { ready: true, isOnline, ...rest }
    yield put({
      type: Actions.STARTUP_LOADED,
      data: { ready: true, isOnline, ...rest },
    })
    if (action.onSuccess) {
      action.onSuccess(data)
    }
  } catch (e) {
    yield put(API.ajaxHandler(Actions.STARTUP_ERROR, e))
    if (action?.onError) {
      action.onError({ error: e })
    }
  }
}

export function* onToken(action, result) {
  //  If you need to refresh a user profile, do it here
  if (result?.id) {
    yield handleResponse(action, 'LOGIN', result, false)
  }
}

export function* handleLogin(action) {
  const session = yield API.auth.Cognito.getSession()
  const token = session.getAccessToken().getJwtToken()
  _data.setToken(token)
  const res = yield getProfile(action)
  API.identify && API.identify(res.id)
}

export function* getProfile(action) {
  return yield getAction(action, `${Project.api}me/`, 'GET_PROFILE')
}



export function* updateProfile(action) {
  yield patchAction(action, `${Project.api}users/me/`, 'UPDATE_PROFILE')
}

export function* login(action) {
  try {
    const data: RequestTypes['login'] = action.data
    const user = yield API.auth.Cognito.login(data.username, data.password)
    yield handleResponse(action, 'LOGIN', user, false)

    action.onSuccess && action.onSuccess(user)
  } catch (e) {
    yield errorHandler(action, 'LOGIN', false, e)
  }
}

// eslint-disable-next-line require-yield
export function* register(action) {
  try {
    // const data:RequestTypes['register'] = action.data;
    // yield API.auth.Cognito.signUp(data.username, data.password);
    // yield action.onSuccess();
    // yield put({ type: Actions.REGISTER_LOADED });
  } catch (e) {
    // yield errorHandler(action, "REGISTER", false, e);
  }
}
export function* logout(action) {
  API.logout()
  yield API.setStoredToken(null)
  yield API.storage.removeItem('user')
  yield API.setStoredRefreshToken(null)
  _data.setToken(null)
  _data.setRefreshToken(null)
  yield API.auth.Cognito.logout()
  yield put({ type: Actions.CLEAR_USER })
  action.onSuccess && action.onSuccess()
}

export function* confirmEmail(action) {
  const data: RequestTypes['confirmEmail'] = action.data
  try {
    const response = yield _data.post(
      `${Project.api}auth/verify?userId=${data.userId}&code=${data.code}`,
      data,
      {
        'content-type': 'application/json',
      },
    )
    yield handleResponse(action, 'CONFIRM_EMAIL', response)
  } catch (e) {
    yield errorHandler(action, 'CONFIRM_EMAIL', false, e)
  }
}

export function* getProject(action) {
  const data: RequestTypes['getProject'] = action.data
  yield getAction(action, `${Project.api}projects/${data.id}`, 'GET_PROJECT')
}

export function* updateProject(action) {
  const data: RequestTypes['updateProject'] = action.data
  yield updateAction(
    action,
    `${Project.api}projects/${data.id}`,
    'UPDATE_PROJECT',
  )
}

export function* createAssessment(action) {
  const currentPath = window.location.pathname;
  const data: RequestTypes['createAssessment'] = action.data

  // Check if the URL contains 'anonymous' to determine the API endpoint
  const apiUrl = currentPath.includes('anonymous')
  ? `${Project.api}anonymousAssessments?projectId=${data.projectId}`
  : `${Project.api}assessments?projectId=${data.projectId}`;

  yield postAction(
    action,
    apiUrl,
    'CREATE_ASSESSMENT',
  )
}

// export function* getAssessment(action) {
//   const data: RequestTypes['getAssessment'] = action.data
//   yield getAction(
//     action,
//     `${Project.api}assessments/${action.data.id}`,
//     'GET_ASSESSMENT',
//   )
// }
export function* getAssessment(action) {

  const currentPath = window.location.pathname;

  const data: RequestTypes['getAssessment'] = action.data

  // Check if the URL contains 'anonymous' to determine the API endpoint
  const apiUrl = currentPath.includes('anonymous')
  ? `${Project.api}anonymousAssessments/${action.data.id}`
  : `${Project.api}assessments/${action.data.id}`;

  yield getAction(
    action,
    apiUrl,
    'GET_ASSESSMENT',
  )
}

// export function* updateAssessment(action) {
//   const data: RequestTypes['updateAssessment'] = action.data

//   yield updateAction(
//     action,
//     `${Project.api}assessments/${data.id}`,
//     'UPDATE_ASSESSMENT',
//   )
// }
export function* updateAssessment(action) {
console.log('action',action);

const updatedAction = {
  ...action,
  data: {
    ...action.data,
    body: action.data.body.map((section) => ({
      ...section,
      successFactors: section.successFactors.map((factor) => ({
        ...factor,
        groups: factor.groups
          .map((group) => ({
            ...group,
            questions: group.questions.filter((question) => question.label), // Keep only questions with a label
          }))
          .filter((group) => group.questions.length > 0), // Remove groups with no questions
      })),
    })),
  },
};
  const currentPath = window.location.pathname;
  
  const data: RequestTypes['updateAssessment'] = action.data

   // Check if the URL contains 'anonymous' to determine the API endpoint
   const apiUrl = currentPath.includes('anonymous')
   ? `${Project.api}anonymousAssessments/${data.id}`
   : `${Project.api}assessments/${data.id}`;

  yield updateAction(
    updatedAction,
    apiUrl,
    'UPDATE_ASSESSMENT',
  )
}

export function* createOrganisation(action) {
  const data: RequestTypes['createOrganisation'] = action.data
  if (data.imageFile) {
    try {
      data.logoMediaKey = yield upload(data.imageFile, 'organisation')
      yield postAction(
        action,
        `${Project.api}orgs/onboard/`,
        'CREATE_ORGANISATION',
      )
    } catch (e) {
      yield errorHandler(action, 'CREATE_ORGANISATION', false, e)
    }
  } else {
    yield postAction(
      action,
      `${Project.api}orgs/onboard/`,
      'CREATE_ORGANISATION',
    )
  }
}

export function* getOrganisation(action) {
  const data: RequestTypes['getOrganisation'] = action.data
  yield getAction(
    action,
    `${Project.api}/${action.data.id}`,
    'GET_ORGANISATION',
  )
}

export function* updateOrganisation(action) {
  const data: RequestTypes['updateOrganisation'] = action.data

  yield updateAction(action, `${Project.api}`, 'UPDATE_ORGANISATION')
}

export function* getAssessmentSummaries(action) {
  const currentPath = window.location.pathname;
  const data: RequestTypes['getAssessmentSummaries'] = action.data

// Check if the URL contains 'anonymous' to determine the API endpoint
const apiUrl = currentPath.includes('anonymous')
? `${Project.api}anonymousAssessments`
: `${Project.api}assessments`;

  yield getAction(
    action,
    apiUrl,
    'GET_ASSESSMENT_SUMMARIES',
  )
}

export function* completeAssessment(action) {

  const currentPath = window.location.pathname;

  const data: RequestTypes['completeAssessment'] = action.data

  // Check if the URL contains 'anonymous' to determine the API endpoint
  const apiUrl = currentPath.includes('anonymous')
  ? `${Project.api}anonymousAssessments/${data.id}/complete`
  : `${Project.api}assessments/${data.id}/complete`;

  yield postAction(
    action,
    apiUrl,
    'COMPLETE_ASSESSMENT',
  )
}

export function* getAssessmentReport(action) {
  const currentPath = window.location.pathname;
  const data: RequestTypes['getAssessmentReport'] = action.data

  // Check if the URL contains 'anonymous' to determine the API endpoint
  const apiUrl = currentPath.includes('anonymous')
  ? `${Project.api}assessments/${data.id}/report`
  : `${Project.api}assessments/${data.id}/report`;

  yield getAction(
    action,
    apiUrl,
    'GET_ASSESSMENT_REPORT',
  )
}

export function* downloadAssessmentReport(action) {
  try {
    const data: RequestTypes['downloadAssessmentReport'] = action.data
    const session = yield call(API.auth.Cognito.getSession);
    const timeZone=Intl.DateTimeFormat().resolvedOptions().timeZone; 
    const token = session?.accessToken?.jwtToken;

    const response = yield call(fetchReport, data.id, token,timeZone);

    if (response.ok) {
      // Get the filename from the Content-Disposition header
      const contentDispositionHeader = response.headers.get('content-disposition');
      let filename = data.name+'.pdf'; // Default filename if not found

      if (contentDispositionHeader) {
        const matches = contentDispositionHeader.match(/filename="(.+)"/);
        if (matches && matches.length > 1) {
          filename = matches[1];
        }
      }

      // Generate a temporary link to trigger the download
      const pdfBlob = yield response.blob();
      const url = window.URL.createObjectURL(pdfBlob);

      downloadFile(url, filename);

      // Clean up the object URL to release resources
      window.URL.revokeObjectURL(url);

    }

  } catch (err) {

  }

}


function downloadFile(url, filename) {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


function fetchReport(id: any, token: any,timeZone:any) {
  return fetch(`${Project.api}assessments/${id}/report-pdf?localTimeZone=${timeZone}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream',
     // 'Authorization': `Bearer ${token}`,
    },
  });
}

export function* createConfirmSignin(action) {
  const data: RequestTypes['createConfirmSignin'] = action.data

  try {
    yield API.auth.Cognito.confirmSignIn(data.authCode)
    yield handleLogin(action)
    yield handleResponse(action, 'CREATE_CONFIRM_SIGNIN', null, false)
  } catch (e) {
    yield errorHandler(action, 'CREATE_CONFIRM_SIGNIN', false, e)
  }
}

export function* deleteUser(action) {
  const data: RequestTypes['deleteUser'] = action.data
  yield deleteAction(action, `${Project.api}users/${data.id}`, 'DELETE_USER')
}

export function* createUser(action) {
  const data: RequestTypes['createUser'] = action.data
  try {
    const response = yield _data.post(`${Project.api}auth/signup`, data, {
      'content-type': 'application/json',
    })
    yield handleResponse(action, 'CREATE_USER', response)
  } catch (e) {
    yield errorHandler(action, 'CREATE_USER', false, e)
  }
}

export function* forgotPassword(action) {
  const data: RequestTypes['forgotPassword'] = action.data
  try {
    const response = yield _data.post(
      `${Project.api}auth/forgot-password`,
      data,
      {
        'content-type': 'application/json',
      },
    )
    yield handleResponse(action, 'FORGOT_PASSWORD', response)
  } catch (e) {
    yield errorHandler(action, 'FORGOT_PASSWORD', false, e)
  }
}

export function* resetPassword(action) {
  const data: RequestTypes['resetPassword'] = action.data
  try {
    const response = yield _data.post(
      `${Project.api}auth/reset-password`,
      data,
      {
        'content-type': 'application/json',
      },
    )
    yield handleResponse(action, 'RESET_PASSWORD', response)
  } catch (e) {
    yield errorHandler(action, 'RESET_PASSWORD', false, e)
  }
}

export function* changePassword(action) {
  yield getAction(
    action,
    `${Project.api}auth/change-password`,
    'CHANGE_PASSWORD',
  )
}

export function* getUser(action) {
  const data: RequestTypes['getUser'] = action.data
  yield getAction(action, `${Project.api}users/${data.id}`, 'GET_USER')
}

export function* updateUser(action) {
  const data: RequestTypes['updateUser'] = action.data
  yield updateAction(action, `${Project.api}users/${data.id}`, 'UPDATE_USER')
}

export const upload = async (
  file: File,
  folderName: string,
  filePrefix = 'image',
) => {
  const params: RequestTypes['getUploadUrl'] = {
    name: `${Date.now().valueOf()}-${file.name}`,
  }
  const urlResponse: RequestUrl = await _data.post(
    `${Project.api}media/upload/${folderName}`,
    params,
  )
  const formData = new FormData()
  formData.append('contentType', file.type)
  formData.append(filePrefix, file)
  const res = await _data.put(urlResponse.url, file, {
    'content-type':
      folderName === 'signature' ? 'multipart/form-data' : file.type,
  })
  return urlResponse.id
}

export function* createUploadFile(action) {
  const data: RequestTypes['createUploadFile'] = action.data
  try {
    const urlResponse: RequestUrl = yield upload(data.file, data.prefix)
    yield handleResponse(action, 'CREATE_UPLOAD_FILE', urlResponse)
  } catch (e) {
    yield errorHandler(action, 'CREATE_UPLOAD_FILE', false, e)
  }
}

export function* createProject(action) {
  const data: RequestTypes['createProject'] = action.data
  yield postAction(
    action,
    `${Project.api}projects?orgId=${data.orgId}`,
    'CREATE_PROJECT',
  )
}

export function* getUsers(action) {``
  const data: RequestTypes['getUsers'] = action.data
  //
  yield getAction(
    action,
    `${Project.api}orgs/${data.orgId}/users/`,
    'GET_USERS',
  )
}

export function* updateUserOrganisationPermissions(action) {
  const data: RequestTypes['updateUserOrganisationPermissions'] = action.data
  yield updateAction(
    action,
    `${Project.api}orgs/${data.orgId}/users/${data.user}`,
    'UPDATE_USER_ORGANISATION_PERMISSIONS',
  )
}

export function* getUserProjectPermissions(action) {
  const data: RequestTypes['getUserProjectPermissions'] = action.data
  // yield handleResponse(action, 'GET_USER_PROJECT_PERMISSIONS', {
  //   projects: [],
  // })
  yield getAction(
    {
      ...action,
    },
    `${Project.api}orgs/${data.orgId}/users/${data.user}`,
    'GET_USER_PROJECT_PERMISSIONS',
  )
}

export function* updateUserProjectPermissions(action) {
  const data: RequestTypes['updateUserProjectPermissions'] = action.data
  yield postAction(
    {
      ...action,
      data: {
        role: data.role,
        linkUrl: data.linkUrl,
      },
    },
    `${Project.api}projects/${data.projectId}/users/${data.user}/`,
    'UPDATE_USER_PROJECT_PERMISSIONS',
  )
  // if (data.permission.id) {
  //
  // } else {
  //   yield postAction(
  //     {
  //       ...action,
  //       data: data.permission,
  //     },
  //     `${Project.api}projects/${data.permission.project}/users/${data.user}/project-permissions`,
  //     'UPDATE_USER_PROJECT_PERMISSIONS',
  //   )
  // }
}

export function* createOrganisationInvite(action) {
  const { orgId, ...rest }: RequestTypes['createOrganisationInvite'] =
    action.data
  yield postAction(
    {
      ...action,
      data: rest,
    },
    `${Project.api}invites?orgId=${action.data.orgId}`,
    'CREATE_ORGANISATION_INVITE',
  )
}

export function* createProjectInvite(action) {
  const data: RequestTypes['createProjectInvite'] = action.data
  yield postAction(
    action,
    `${Project.api}invites?projectId=${data.projectId}`,
    'CREATE_PROJECT_INVITE',
  )
}

export function* fetchThemeIcons(id: any, token: any) {
  return fetch(`${Project.api}assessments/${id}/report-pdf`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream',
      'Authorization': `Bearer ${token}`,
    },
  });
}

// END OF YIELDS

function* rootSaga() {
  yield all([
    takeLatest(Actions.LOGIN, login),
    takeLatest(Actions.GET_PROFILE, getProfile),
    takeLatest(Actions.REGISTER, register),
    takeLatest(Actions.FORGOT_PASSWORD, forgotPassword),
    takeLatest(Actions.RESET_PASSWORD, resetPassword),
    takeLatest(Actions.CHANGE_PASSWORD, changePassword),
    takeLatest(Actions.LOGOUT, logout),
    takeLatest(Actions.CONFIRM_EMAIL, confirmEmail),
    takeLatest(Actions.UPDATE_USER, updateUser),
    takeLatest(Actions.STARTUP, startup),
    takeLatest(Actions.GET_PROJECT, getProject),
    takeLatest(Actions.UPDATE_PROJECT, updateProject),
    takeEvery(Actions.CREATE_ASSESSMENT, createAssessment),
    takeLatest(Actions.GET_ASSESSMENT, getAssessment),
    takeEvery(Actions.UPDATE_ASSESSMENT, updateAssessment),
    takeEvery(Actions.CREATE_ORGANISATION, createOrganisation),
    takeLatest(Actions.GET_ORGANISATION, getOrganisation),
    takeEvery(Actions.UPDATE_ORGANISATION, updateOrganisation),
    takeLatest(Actions.GET_ASSESSMENT_SUMMARIES, getAssessmentSummaries),
    takeEvery(Actions.COMPLETE_ASSESSMENT, completeAssessment),
    takeLatest(Actions.GET_ASSESSMENT_REPORT, getAssessmentReport),
    takeLatest(Actions.DOWNLOAD_ASSESSMENT_REPORT, downloadAssessmentReport),
    takeEvery(Actions.CREATE_CONFIRM_SIGNIN, createConfirmSignin),
    takeEvery(Actions.DELETE_USER, deleteUser),
    takeEvery(Actions.CREATE_USER, createUser),
    takeEvery(Actions.GET_USER, getUser),
    takeEvery(Actions.UPDATE_USER, updateUser),
    takeEvery(Actions.CREATE_UPLOAD_FILE, createUploadFile),

    takeEvery(Actions.CREATE_PROJECT, createProject),
    takeLatest(Actions.GET_USERS, getUsers),
    takeEvery(
      Actions.UPDATE_USER_ORGANISATION_PERMISSIONS,
      updateUserOrganisationPermissions,
    ),
    takeLatest(Actions.GET_USER_PROJECT_PERMISSIONS, getUserProjectPermissions),
    takeEvery(
      Actions.UPDATE_USER_PROJECT_PERMISSIONS,
      updateUserProjectPermissions,
    ),
    takeEvery(Actions.CREATE_ORGANISATION_INVITE, createOrganisationInvite),
    takeEvery(Actions.CREATE_PROJECT_INVITE, createProjectInvite),
    
    // END OF TAKE_LATEST
    // KEEP THE ABOVE LINE IN, IT IS USED BY OUR CLI
  ])
}

export default rootSaga
